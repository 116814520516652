import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react'

import {
  graphql,
  Link,
  useStaticQuery
} from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  FormQuantityField,
  FormTextField,
  PageTitle,
  SpecificContactForm,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import SnipcartButton from '../../components/snipcart-button'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


type CartItem = {
  name: string,
  quantity: number
}


const KitsPage = () => {
  const [kit15_quantity, setKit15Quantity] = useState<number>(1)
  const [kit16_quantity, setKit16Quantity] = useState<number>(1)
  const [kit17_quantity, setKit17Quantity] = useState<number>(1)
  const [kit18_quantity, setKit18Quantity] = useState<number>(1)
  const [kit19_quantity, setKit19Quantity] = useState<number>(1)
  const [kit20_quantity, setKit20Quantity] = useState<number>(1)
  const [kit21_quantity, setKit21Quantity] = useState<number>(1)
  const [kit22_quantity, setKit22Quantity] = useState<number>(1)
  const [kit23_quantity, setKit23Quantity] = useState<number>(1)
  const [kit24_quantity, setKit24Quantity] = useState<number>(1)
  const [kit25_quantity, setKit25Quantity] = useState<number>(1)
  const [kit26_quantity, setKit26Quantity] = useState<number>(1)
  const [kit27_quantity, setKit27Quantity] = useState<number>(1)
  const [kit28_quantity, setKit28Quantity] = useState<number>(1)
  const [kit29_quantity, setKit29Quantity] = useState<number>(1)

  const [kit15_reservation_code, setKit15ReservationCode] = useState<string>('')
  const [kit16_reservation_code, setKit16ReservationCode] = useState<string>('')
  const [kit17_reservation_code, setKit17ReservationCode] = useState<string>('')
  const [kit18_reservation_code, setKit18ReservationCode] = useState<string>('')
  const [kit19_reservation_code, setKit19ReservationCode] = useState<string>('')
  const [kit20_reservation_code, setKit20ReservationCode] = useState<string>('')
  const [kit21_reservation_code, setKit21ReservationCode] = useState<string>('')
  const [kit22_reservation_code, setKit22ReservationCode] = useState<string>('')
  const [kit23_reservation_code, setKit23ReservationCode] = useState<string>('')
  const [kit24_reservation_code, setKit24ReservationCode] = useState<string>('')
  const [kit25_reservation_code, setKit25ReservationCode] = useState<string>('')
  const [kit26_reservation_code, setKit26ReservationCode] = useState<string>('')
  const [kit27_reservation_code, setKit27ReservationCode] = useState<string>('')
  const [kit28_reservation_code, setKit28ReservationCode] = useState<string>('')
  const [kit29_reservation_code, setKit29ReservationCode] = useState<string>('')

  const data = useStaticQuery(graphql`
    {
      allProductsJson {
        edges {
          node {
            active
            kitPrice
            shippingCost
            shippingDescription
            snipcartId
            snipcartName
            snipcartPrice
            snipcartPath
            snipcartDescription
            snipcartShippable
          }
        }
      }
    }
  `)

  const edges = data.allProductsJson.edges

  const page_products: string[] = [
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29"
  ]

  const product_state_variables: Record<string, [number, string]> = {
    "kits01": [kit15_quantity, kit15_reservation_code],
    "kits65a": [kit16_quantity, kit16_reservation_code],
    "kits71/2b": [kit17_quantity, kit17_reservation_code],
    "kits71/2c": [kit18_quantity, kit18_reservation_code],
    "kits71/2d": [kit19_quantity, kit19_reservation_code],
    "kits71/2a": [kit20_quantity, kit20_reservation_code],
    "kits84b": [kit21_quantity, kit21_reservation_code],
    "kits84a": [kit22_quantity, kit22_reservation_code],
    "kits81/4b": [kit23_quantity, kit23_reservation_code],
    "kits81/4a": [kit24_quantity, kit24_reservation_code],
    "kit64a": [kit25_quantity, kit25_reservation_code],
    "kit64b": [kit26_quantity, kit26_reservation_code],
    "kit74b": [kit27_quantity, kit27_reservation_code],
    "kit85a": [kit28_quantity, kit28_reservation_code],
    "kit85b": [kit29_quantity, kit29_reservation_code]
  }

  const product_functions: Record<string, [Dispatch<SetStateAction<number>>, Dispatch<SetStateAction<string>>]> = {
    "setKITS01": [setKit15Quantity, setKit15ReservationCode],
    "setKITS65A": [setKit16Quantity, setKit16ReservationCode],
    "setKITS71/2B": [setKit17Quantity, setKit17ReservationCode],
    "setKITS71/2C": [setKit18Quantity, setKit18ReservationCode],
    "setKITS71/2D": [setKit19Quantity, setKit19ReservationCode],
    "setKITS71/2A": [setKit20Quantity, setKit20ReservationCode],
    "setKITS84B": [setKit21Quantity, setKit21ReservationCode],
    "setKITS84A": [setKit22Quantity, setKit22ReservationCode],
    "setKITS81/4B": [setKit23Quantity, setKit23ReservationCode],
    "setKITS81/4A": [setKit24Quantity, setKit24ReservationCode],
    "setKIT64A": [setKit25Quantity, setKit25ReservationCode],
    "setKIT64B": [setKit26Quantity, setKit26ReservationCode],
    "setKIT74B": [setKit27Quantity, setKit27ReservationCode],
    "setKIT85A": [setKit28Quantity, setKit28ReservationCode],
    "setKIT85B": [setKit29Quantity, setKit29ReservationCode]
  }

  return (
    <React.Fragment>
      <SEO
        title="Payment"
        description=""
        canonicalUrl="https://bscs.org/openscied/kits"
      />
      <Layout>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to='/'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              OpenSciEd
            </BreadcrumbItem>
            <BreadcrumbItem>
              Kits
            </BreadcrumbItem>
          </Breadcrumb>
          <PageTitle>OpenSciEd Kits</PageTitle>
          <TitleBorder />
          <div className="mb-3 flex flex-wrap-reverse">
            <div className='flex flex-wrap-reverse'>
              <div className='flex flex-wrap w-full md:w-3/4'>
                {edges.map((edge) => {
                  if (page_products.includes(edge.node.snipcartId)) {
                    // const DescriptionComponent = product_descriptions[`${edge.node.snipcartName.replace(' ', '')}Description`]
                    const kit_product_functions = product_functions[`set${edge.node.snipcartName.replace(' ', '')}`]
                    const setProductQuantity: Dispatch<SetStateAction<number>> = kit_product_functions[0]
                    let productReservationCodeFunction: Dispatch<SetStateAction<string>>

                    if (kit_product_functions.length > 1) {
                      productReservationCodeFunction = kit_product_functions[1]
                    }

                    const product: string = edge.node.snipcartName.toLowerCase().replace(' ', '')

                    return (
                      <React.Fragment key={`product-${edge.node.snipcartName}-${edge.node.snipcartId}`}>
                        <div className='mb-5 w-full md:w-1/2 xl:w-1/3'>
                          <VerticalCard className="h-full">
                            <VerticalCardBody>
                              <h2>{edge.node.snipcartName}</h2>
                              <p>{edge.node.snipcartDescription}</p>
                              <div className="flex justify-end flex-wrap">
                                <div className="w-full">
                                  <hr />
                                  <div className="flex py-2">
                                    <strong className="text-left">Kit Price:</strong>
                                    <span className="ml-auto text-right">
                                      ${edge.node.kitPrice}
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="flex py-2">
                                    <strong className="text-left">
                                      Shipping Cost:
                                    </strong>
                                    <span className="ml-auto text-right">
                                      {
                                        edge.node.shippingDescription ||
                                        `$${edge.node.shippingCost}`
                                      }
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="flex py-2">
                                    <strong className="text-left">
                                      Total:
                                    </strong>
                                    <span className="ml-auto text-right">
                                      ${edge.node.snipcartPrice}
                                    </span>
                                  </div>
                                  <hr />
                                  <div className="flex py-2">
                                    <strong className="text-left">
                                      Quantity:
                                    </strong>
                                    <span className="ml-auto text-right">
                                      <div
                                        id={`${product}-quantity-setter-wrapper`}
                                        className="mb-3 ml-auto"
                                      >
                                        <FormQuantityField
                                          id={`${product}-quantity-setter`}
                                          label="Quantity"
                                          setValue={(value: number) => setProductQuantity(value)}
                                        />
                                      </div>
                                      <div
                                        id={`${product}-quantity-number-wrapper`}
                                        className="mb-3 ml-auto"
                                        style={{ display: 'none' }}
                                      >
                                        <p className="snipcart-summary">
                                          <span id={`${product}-quantity-number`}>
                                            {product_state_variables[`${product}`][0]}
                                          </span>
                                        </p>
                                      </div>
                                    </span>
                                  </div>
                                  <React.Fragment>
                                    <hr />
                                    <div className="flex py-2">
                                      <strong className="text-left">
                                        Reservation Code:
                                      </strong>
                                      <div
                                        className="mb-3 ml-auto align-self-middle"
                                      >
                                        <FormTextField
                                          className="p-0"
                                          id={`${product}-reservation-code`}
                                          optional={true}
                                          setValue={
                                            (value: string) => productReservationCodeFunction(value)
                                          }
                                          setValid={(valid: boolean) => valid}
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                                <div className="flex flex-column">
                                  <div className="ml-auto">
                                    <SnipcartButton
                                      id={edge.node.snipcartId}
                                      name={edge.node.snipcartName}
                                      price={edge.node.snipcartPrice}
                                      url={
                                        process.env.GATSBY_ENVIRONMENT === "production"
                                          ? `https://bscs.org${edge.node.snipcartPath}`
                                          : process.env.GATSBY_ENVIRONMENT === "stage"
                                            ? `https://stage.bscs.org${edge.node.snipcartPath}`
                                            : `http://127.0.0.1:8000${edge.node.snipcartPath}`
                                      }
                                      description={edge.node.snipcartDescription}
                                      shippable={edge.node.snipcartShippable}
                                      quantity={
                                        product_state_variables[`${product}`][0]
                                      }
                                      metadata={{
                                        "reservation_code": product_state_variables[`${product}`][1]
                                      }}
                                    >
                                      Order {edge.node.snipcartName}
                                    </SnipcartButton>
                                  </div>
                                </div>
                              </div>
                            </VerticalCardBody>
                          </VerticalCard>
                        </div>
                      </React.Fragment>
                    )
                  }

                  return (
                    <React.Fragment key={`product-${edge.node.snipcartName}-${edge.node.snipcartId}`} />
                  )
                })}
              </div>
              <div className="w-full md:w-1/4">
                <VerticalCard className="mb-3">
                  <VerticalCardBody>
                    <h3 className="card-title h5 mb-0">Purchase Order Form</h3>
                    <p className="mb-0">If you cannot pay online via credit card, use the following Purchase Order form to order kits for Fall 2020 field testing.</p>
                    <p>
                      <a
                        href="https://media.bscs.org/bscsmw/purchase_order_round_5.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Purchase Order Form&nbsp;
                        <sup>
                          <i
                            style={{fontSize: '.65rem'}}
                            className="fas fa-external-link-alt"
                          />
                        </sup>
                      </a>
                    </p>
                  </VerticalCardBody>
                </VerticalCard>

                <VerticalCard>
                  <VerticalCardBody>
                    <h3 className="card-title h5">Questions?</h3>
                    <SpecificContactForm sendto="KITS" />
                  </VerticalCardBody>
                </VerticalCard>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default KitsPage

